@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
}

#body {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

ul {
    margin-bottom: 0 !important;
}

a {
    text-decoration: none;
}

li {
    list-style-type: none !important;
}

p {
    margin: 0 !important;
}

input {
    outline: none;
}

button:disabled {
    cursor: not-allowed !important;
}

.full-width {
    width: 100%;
}

.mt-24 {
    margin-top: 24px;
}

.mt-12 {
    margin-top: 12px !important;
}

.mb-24 {
    margin-bottom: 24px !important;
}

.pointer {
    cursor: pointer !important;
}

.center-text {
    text-align: center !important;
}

.main-content {
    margin: 0 auto;
    display: block;
    width: 80%;
}

.ant-picker-dropdown-range {
    z-index: 1000000 !important;
}

.wrapper-input {
    margin-bottom: 12px;

    .label-form {
        display: block;
        margin-bottom: 6px;
        font-size: 14px;
    }

    .input-form {
        width: 100%;
        border: 1px solid lightgrey;
        display: block;
        padding: 6px 12px;
        box-sizing: border-box;
        border-radius: 5px;
    }
}

.btn {
    // background-color: #fff;
    // width: 100%;
    height: 40px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 12px;
}

.full-width {
    width: 100% !important;
}

.center-items {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.loading {
    position: absolute;

    &::after {
        content: ' ';
        display: block;
        width: 12px;
        height: 12px;
        margin: 8px;
        border-radius: 50%;
        border: 2px solid rgb(255, 128, 0);
        border-color: rgb(255, 128, 0) transparent rgb(255, 128, 0) transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.underline-menu {
    display: inline-block;
    padding: 35px 10px;
    position: relative;

    &::after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        background: red;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
    }

    &:hover {
        &::after {
            width: 100%;
            left: 0;
        }
    }
}

.nav-menu {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    ul {
        position: relative;
        display: flex;
        align-items: center;

        li {
            margin-right: 32px;
            display: inline-block;

            a {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: $textColor;

                &:hover {
                    color: red !important;
                }
            }

            .sub-menu {
                position: absolute;
                background-color: #fff;
                display: block;
                min-width: 200px;
                transform: scaleY(0);
                visibility: hidden;
                opacity: 0;
                z-index: 11;
                transform-origin: 0 0;
                top: 100%;
                transition: all 0.3s ease-out 0s;
                padding: 12px 0;
                border: 1px solid lightgray;

                li {
                    margin: 0;
                    display: block;
                    padding: 0 12px;

                    &:not(:last-child) {
                        margin-bottom: 12px;
                    }

                    a {
                        padding: 6px 0 !important;
                    }
                }
            }

            &:hover .sub-menu {
                opacity: 1;
                transform: scaleY(1);
                visibility: visible;
            }
        }
    }
}

.input-is-danger {
    border-color: #ff0074 !important;
}

.is-danger {
    color: #ff0074 !important;
}

.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 10px;
}

.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    margin-left: 0;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #fb8ca7;
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #fb8ca7;
}

.ant-steps-label-vertical .ant-steps-item-icon {
    margin-left: 0;
}

.ant-steps-item-tail {
    display: none;
}