#banner {
    display: block;
    padding: 30px 0 42px 0;
    box-sizing: border-box;
    position: relative;
    background: #E8F8FF;
    padding-bottom: 400px;

    #banner-content {
        display: grid;
        text-align: center;

        #banner-title {
            font-size: 3.5em;
            width: 1200px;
            margin: 0 auto;
            font-weight: bold;
        }

        #banner-desc {
            // line-height: 38px;
            margin: 1em 0 !important;
            font-size: 1.5em;
        }

        #banner-started_btn {
            background-color: #FF5A80B2;
            color: #fff;
            width: 150px;
            height: 2em;
            font-size: 1.5em;
            margin: 27px auto 0px auto;
        }

        #banner-pig {
            display: block;
            margin: 0 auto;
            height: 10px;
        }

        .map-user {
            .map-title {
                font-weight: 700;
                font-size: 30px;
                text-align: center;
                color: #1E1E1E;
            }

            .map-sub-title {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                color: #858585;
            }

            .gmap_iframe {
                height: 600px;
                width: 100%;
                border-radius: 40px;
                // margin: 50px;
                padding: 50px;
            }
        }


    }


}

#management {
    padding: 1em 5em;
    box-sizing: border-box;
    display: block;

    #management-intro {
        text-align: center;
        display: grid;
        justify-content: center;

        #intro-title {
            font-weight: 800;
            font-size: 48px;
            line-height: 64px;
            color: #18191F;
        }

        #intro-desc {
            width: 734px;
            font-size: 18px;
            line-height: 32px;
            color: #18191F;
            margin-top: 22px;
        }
    }

    #wrapper-content {
        display: grid;
        margin-top: 2em;
        // grid-template-areas:
        //     'm-content1 n1 m-content2 n2 m-content3'
        //     'n3 m-content4 n4  m-content5 n5';

        // .m-content1 {
        //     grid-area: m-content1;
        // }

        // .m-content2 {
        //     grid-area: m-content2;
        // }

        // .m-content3 {
        //     grid-area: m-content3;
        // }

        // .m-content4 {
        //     grid-area: m-content4;
        // }

        // .m-content5 {
        //     grid-area: m-content5;
        // }

        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-row-gap: 2em;
        grid-column-gap: 4em;
        // .management-content:nth-child(1) {
        //     grid-area: 1 / 1 / 2 / 3;
        //     margin-right: 14vw;
        // }

        // .management-content:nth-child(2) {
        //     grid-area: 1 / 2 / 2 / 4;
        //     margin-left: 14vw;
        // }

        // .management-content:nth-child(3) {
        //     grid-area: 2 / 1 / 3 / 2;
        // }

        // .management-content:nth-child(4) {
        //     grid-area: 2 / 2 / 3 / 3;
        //     // margin: 0 1em;
        //     // width: 150%;
        // }

        // .management-content:nth-child(5) {
        //     grid-area: 2 / 3 / 3 / 4;
        // }
    }

    #wrap-content-row-1 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 30px;
    }

    #wrap-content-row-2 {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 30px;
    }

    .management-content {
        text-align: center;
        padding: 2em;
        padding-top: 1em;
        border-radius: 10px;
        background-color: #e8f8ff;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .management-icon {
            height: 100px;
            text-align: center;
            align-items: center;
        }

        .content-title {
            color: #18191F;
            font-weight: 600;
            font-size: 24px;
            // line-height: 32px;
        }

        .content-desc {
            margin-top: 16px;
            color: #18191F;
            font-size: 16px;
        }
    }
}

#story {
    padding: 3em;
    display: block;
    box-sizing: border-box;
    position: relative;
    background: #e8f8ff;

    #banner-pig {
        height: 25em;
    }

    #wrapper-story {
        display: grid;
        justify-self: center;
        grid-template-areas:
            'storyTitle content2'
            'content1 content2'
            'content1 content3'
            'n content3';
        width: 1000px;
        margin: -2em auto -3em;

        .story-content {
            background: #FFFFFF;
            padding: 1.75em 2em;
            box-sizing: border-box;
            box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
            border-radius: 8px;
            width: 600px;
        }

        .content-title {
            color: #33475B;
            font-size: 1.5em;
            line-height: 24px;
            font-weight: bold;
        }

        .story-title {
            grid-area: storyTitle;
            position: relative;
            left: -80px;
            top: 0;

            h1 {
                font-size: 3.5em;
            }

            p {
                font-size: 22px;
                margin-top: 12px;
            }

            &::before {
                content: "\201C";
                font-size: 30em;
                height: 82px;
                line-height: 0.78;
                line-height: 1;
                position: absolute;
                z-index: 1;
                left: -160px;
                top: -35px;
                font-family: sans-serif, serif;
                color: #2EC5CE;
                opacity: .5;
            }
        }

        .content1 {
            grid-area: content1;
            position: relative;
            left: -80px;
            top: -40px;
        }

        .content2 {
            // grid-area: content2;

        }

        .content3 {
            grid-area: content3;
            position: relative;
            left: 0px;
            top: -60px;
        }

        .quote-wrapper {
            position: relative;
            padding-left: 32px;
            box-sizing: border-box;
            margin: 1em 0;
        }

        .text {
            width: 100%;
            height: 100%;
            position: relative;
            margin: 0;
        }

        .text p {
            height: 100%;
            padding: 0;
            font-size: 1em;
            line-height: 32px;
        }

        .quote-wrapper::before {
            content: "\201C";
            font-size: 48px;
            height: 82px;
            line-height: 0.78;
            line-height: 1;
            position: absolute;
            z-index: 1;
            left: 0;
            font-family: sans-serif, serif;
            color: #2EC5CE;
        }

        .story-owner {
            padding-left: 32px;
            box-sizing: border-box;

            .name {
                font-weight: 600;
            }

            .address {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #ABABAB;
            }
        }
    }

    .main-content-home {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2em;

        .content {
            width: 800px;
            display: flex;
            flex-direction: column;
            border: 5px solid #b2c8e6;
            padding: 1em;
            border-radius: 10px;
            gap: 1em;
            background-color: white;
            // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

            .title-content {
                font-size: 1.5em;
                font-weight: 600;
                text-align: center;
            }

            .lst-content {
                display: flex;
                flex-direction: column;
                gap: 1em;
                text-align: justify;
                text-justify: inter-word;
            }

            .content-item {
                display: flex;
                justify-content: start;
                align-items: baseline;
                font-size: 1em;
                gap: 1em;

                svg {
                    font-size: 1em;
                }
            }
        }

    }
}

.news-pigs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    margin-top: 2em;
    margin-bottom: 1em;

    .news-title {
        font-weight: bold;
        font-size: 48px;
        line-height: 64px;
        color: #18191F;
    }

    .lst-news {
        display: flex;
        gap: 1em;
        width: 80vw;

        // Add Scroll x
        overflow-x: auto;
        overflow-y: hidden;

        .item-news {
            width: 400px;
            min-width: 400px;
            cursor: pointer;

            .image-news {
                width: 100%;
                height: 250px;
                border-radius: 10px;
            }

            .content-news {
                display: flex;
                flex-direction: column;
                gap: .5em;
                margin-bottom: 1em;

                h2 {
                    font-size: 1.5em;
                    font-weight: 600;
                    // height: 40px;

                    //Chỉ hiển thị 2 dòng
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                }

                p {
                    font-size: 1em;

                    //Chỉ hiển thị 3 dòng
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
    }
}
@media (max-width: 400px) {
    #banner {

        #banner-content {

            #banner-title {
                font-size: 50px;
            }

        }
    }
}

@media (max-width: 5500px) {
    #map {
        position: absolute;
        top: 210px;
        left: 100px;
        bottom: 0;
        width: 40%;
        height: 55%;

        // color: #e8f8ff;
        // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        // border-radius: 16px;
        // margin-top: 10em;
        // height: 500px;

    }

    .video-player {
        position: absolute;
        right: 100px;
        top: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 700px;

        .text-video {
            font-size: 1.25em;
            font-weight: 600;
            margin-top: -1em;
            margin-bottom: 1em;
        }

        div {
            // width: 100% !important;
            // height: 100% !important;

            video {
                // border-radius: 16px;
                // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                // color: #0099d1;
                margin-top: -20px;
                margin-left: 30px;
                height: 90% !important;
                width: 90% !important;
            }
        }
    }

    #banner {
        #banner-content {
            justify-content: center;

            #banner-title {
                width: 4000px;
            }

        }
    }
}

@media (max-width: 3500px) {
    #banner {
        #banner-content {

            #banner-title {
                width: 2000px;
            }

        }
    }
}

@media (max-width: 2500px) {
    #banner {
        #banner-content {

            #banner-title {
                width: 1400px;
            }

        }
    }
}

@media (max-width: 1440px) {
    #banner {
        #banner-content {

            #banner-title {
                width: 1200px;
                margin: 0 1em;
            }

        }
    }

    #map {
        top: 290px;
        left: 50px;
    }

    .video-player {
        top: 290px;
        right: 0px;
    }
}

@media (max-width: 1300px) {
    #banner {
        #banner-content {
            justify-content: center;

            #banner-title {
                font-size: 3em;
                width: 1000px;
                margin: 0 1em;
            }

        }
    }

    #map {
        top: 240px;
        left: 50px;
    }

    .video-player {
        top: 240px;
        right: 0px;

        div {
            video {
                margin-top: -20px;
                margin-left: 70px;
                height: 90% !important;
                width: 80% !important;
            }
        }

    }
}

@media (max-width: 1200px) {
    .news-pigs {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;
        margin-top: 2em;

        .news-title {
            font-weight: bold;
            font-size: 48px;
            line-height: 64px;
            color: #18191F;
        }

        .lst-news {
            display: flex;
            gap: 1em;
            width: 50vw;

            // Add Scroll x
            overflow-x: auto;
            overflow-y: hidden;

            .item-news {
                width: 300px;
                min-width: 300px;

                .image-news {
                    width: 100%;
                    border-radius: 10px;
                }

                .content-news {
                    display: flex;
                    flex-direction: column;
                    gap: 1em;

                    h2 {
                        font-size: 1.5em;
                        font-weight: 600;
                    }

                    p {
                        font-size: 1em;
                    }
                }
            }
        }
    }
}

.span-map {
    z-index: 10;
    left: 22%;
    top: 66%;
    position: absolute;
}

.span-map-noti {
    z-index: 10;
    position: absolute;
    right: 0;
    bottom: 10%;
    // style text
    font-weight: 600;
    color: #18191F;
    text-align: center;
    // style box
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
    // style button
    font-style: italic;
}


.lds-spinner {
    color: official;
    display: inline-block;
    z-index: 10;
    left: 24.5%;
    top: 53%;
    position: absolute;
}

.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #18191F;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}