.news-pig-body {
  font-family: 'Inter';
  font-style: normal;
  padding: 3% 10% 0 10%;
  background-color: #F6F6F6;

  .button-farmList {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .farmList-h2 {
      font-size: 24px;
      font-weight: bolder;
    }

    .search-and-sort {
      // width: 20em;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-input-group-wrapper {
        padding-right: 0.5em;

        .ant-input-affix-wrapper:hover,
        .ant-input-group-wrapper:focus {
          color: #FF5A80 !important;
          border-color: #FF5A80 !important;
          background: #FFFFFF !important;
          outline: none !important;
          box-shadow: 0 0 0 2px rgba(255, 90, 128, 0.2) !important;
        }
      }

      .ant-select {
        width: 20em !important;

        .ant-select-selector {}

        .ant-select-selector:hover,
        .ant-select-selector:focus {
          color: black;
          background-color: #FFFFFF;
          border-radius: 10px;
        }
      }
    }
  }

  button {
    background: #FF5A80;
    border-radius: 6px;
    font-weight: 500;
    font-size: 1em;
    color: #FFFFFF;
  }

  button:hover {
    scale: 1.1;
    border: 1px solid #FF5A80;
  }

  .table-farmList {
    margin-top: 1em;

    .ant-table-wrapper {
      border-radius: 6px;

      .ant-table-thead {
        tr {
          th {
            font-weight: 700;
            text-align: center;
          }
        }
      }

      .ant-table-tbody {
        .ant-table-cell {
          text-align: center;
        }
      }
    }

    .even-row {
      background-color: #ffecee;
    }

    .odd-row {
      background-color: #ffffff;
    }

    th {
      background-color: #ffc8d4;
    }


  }


}

.ant-btn:hover,
.ant-btn:focus {
  color: #FF5A80 !important;
  border-color: #FF5A80 !important;
  background: #FFFFFF !important;
}

.ant-space.ant-space-horizontal.ant-space-align-center {
  gap: 1em !important;
}

.ant-pagination {
  display: flex;
  align-items: center;
  justify-content: center !important;

  .ant-pagination-item {
    border-radius: 10px;
  }

  .ant-pagination-item-link {
    border-radius: 10px !important;
  }

  .ant-pagination-item-active,
  .ant-pagination-item-active:focus-visible,
  .ant-pagination-item-active:hover,
  .ant-pagination-item:focus-visible,
  .ant-pagination-item:hover {
    border-color: #FF5A80;

    a {
      color: #FF5A80;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link:hover {
      border-color: #FF5A80;
      color: #FF5A80;
    }
  }

  .anticon svg {
    margin-bottom: 6px;
  }
}

.ant-spin {
  .ant-spin-dot-item {
    background-color: #FF5A80;
  }

  .ant-spin-text {
    color: #FF5A80;
  }
}

.ant-modal-root {
  .ant-modal-wrap {


    .ant-modal {
      .ant-modal-content {
        height: 100% !important;
        max-height: 100% !important;
      }

      .ant-modal-body {
        .action-btn {
          display: flex;
          justify-content: flex-end;
          gap: 1em;

          .cancel-btn {
            color: rgba(0, 0, 0, 0.85) !important;
            background-color: white !important;
            border: 1px solid rgba(0, 0, 0, 0.85) !important;
          }

          .cancel-btn:hover {
            color: #FF5A80 !important;
            background-color: white !important;
            border: 1px solid #FF5A80 !important;
          }
        }
      }
    }

    .delete-modal {
      .ant-modal-body {
        display: flex;
        flex-direction: column;
        gap: 1em;


      }

    }
  }


}

.ant-slider-handle {
  border: solid 2px #ffc8d4 !important;
}

.ant-slider-track {
  background-color: #ffc8d4 !important;
}

.ant-input:hover {
  border-color: #FF5A80 !important;
}

.ant-input:focus {
  border-color: #FF5A80 !important;
  outline: none !important;
  box-shadow: 0 0 0 2px rgba(255, 90, 128, 0.2) !important;
}

.ant-btn-primary {
  background-color: #FF5A80 !important;
  border-color: #FF5A80 !important;
  color: #FFFFFF !important;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
  border-color: #FF5A80 !important;
  outline: none !important;
  box-shadow: 0 0 0 2px rgba(255, 90, 128, 0.2) !important;
}

.ant-form-item-label>label {
  width: 12em;
  justify-content: end;
}

.select-behaviour-pig {
  width: 100%;
  color: #FF5A80 !important;
  background-color: #F6F6F6 !important;
}