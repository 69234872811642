// .lst-picker {
.tabs {
    .ant-tabs-content-holder {
        overflow: scroll;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #ff5a8178;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #ff5a81b4;
    }

}

.ant-picker-datetime-panel {

    .ant-picker-date-panel {
        display: none;

    }

    .ant-picker-time-panel {
        ::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #ff5a8178;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #ff5a81b4;
        }

        .ant-picker-header {
            display: none;
        }
    }
}

// }
.eating-picker,
.lying-picker,
.playing-picker {
    width: 225px !important;
}


.lst-temp-tag {

    overflow-y: scroll;
    height: 150px;
    border: 1px solid #ffdade;

    .select-temp-tag {
        cursor: pointer;
        padding: 8px;
        padding-left: 1em;
        user-select: none;
        transition: all 0.3s ease-in-out;
        /* Prevent text selection */
    }

    .select-temp-tag:hover {
        background-color: #deebff;
    }

    .select-temp-tag.selected {
        background-color: #bfd8fd;
    }
}

.basic-multi-select {
    width: 100%;
}