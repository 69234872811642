.tabs {
    .ant-tabs-content-holder {
        overflow: scroll;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #ff5a8178;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #ff5a81b4;
    }

}

.ant-table-pagination.ant-pagination {
    padding-bottom: 1em;
}

.table-temp {

    .even-row {
        background-color: #ffecee;
    }

    .odd-row {
        background-color: #ffffff;
    }

    th {
        background-color: #ffc8d4;
    }
}

.pen-video {
    font-size: 16px;
    font-weight: 500;
    color: white;
    height: 235px;
    background-color: #6b7280;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1vw;

    .pen-video-button {
        background-color: #9ca3af;
        border: 1px solid #9ca3af;
        color: white;
        font-size: 16px;
        height: 50px;
        width: 120px;
        border-radius: 6px;

        &:hover {
            background-color: #9ca3af !important;
            border: 1px solid #9ca3af !important;
            color: white !important;
        }

        &:active {
            background-color: #9ca3af !important;
            border: 1px solid #9ca3af !important;
            color: white !important;

        }
    }
}

.pen-video-loading {
    height: 40vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#tableTitle {
    span {
        font-size: .5em;
        font-style: italic;
    }
}

// .ant-input-group-wrapper {
//     width: 20em;

//     .ant-input-affix-wrapper:hover,
//     .ant-input-group-wrapper:focus {
//         color: #FF5A80 !important;
//         border-color: #FF5A80 !important;
//         background: #FFFFFF !important;
//         outline: none !important;
//         box-shadow: 0 0 0 2px rgba(255, 90, 128, 0.2) !important;
//     }

// }

// button {
//     background: #FF5A80;
//     border-radius: 6px;
//     font-weight: 500;
//     font-size: 1em;
//     color: #FFFFFF;
// }

// button:hover {
//     scale: 1.1;
//     border: 1px solid #FF5A80;
// }


.table-tag-pig {
    // margin: 1em 9vw;

    .ant-table-wrapper {
        border-radius: 6px;

        .ant-table-thead {
            tr {
                th {
                    font-weight: 700;
                    text-align: center;
                    background: #ffc8d4 !important;
                }

                .ant-table-cell:nth-child(5) {
                    // width: 7em;
                }

                .ant-table-cell:nth-child(7) {
                    width: 10em;
                }
            }
        }

        .ant-table-tbody {
            .ant-table-cell {
                text-align: center;
            }

        }
    }

    .even-row {
        background-color: #ffecee;
    }

    .odd-row {
        background-color: #ffffff;
    }

    th {
        background-color: #ffc8d4;
    }


}


.ant-btn:hover,
.ant-btn:focus {
    color: #FF5A80 !important;
    border-color: #FF5A80 !important;
    background: #FFFFFF !important;
}

.ant-space.ant-space-horizontal.ant-space-align-center {
    gap: .5em !important;
}

.ant-pagination {
    display: flex;
    align-items: center;
    justify-content: center !important;

    .ant-pagination-item {
        border-radius: 10px;
    }

    .ant-pagination-item-link {
        border-radius: 10px !important;
    }

    .ant-pagination-item-active,
    .ant-pagination-item-active:focus-visible,
    .ant-pagination-item-active:hover,
    .ant-pagination-item:focus-visible,
    .ant-pagination-item:hover {
        border-color: #FF5A80;

        a {
            color: #FF5A80;
        }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        .ant-pagination-item-link:hover {
            border-color: #FF5A80;
            color: #FF5A80;
        }
    }

    .anticon svg {
        margin-bottom: 6px;
    }
}

.ant-spin {
    .ant-spin-dot-item {
        background-color: #FF5A80;
    }

    .ant-spin-text {
        color: #FF5A80;
    }
}

.ant-modal-root {
    .ant-modal-wrap {

        .ant-modal {
            width: 500px;
            max-width: 500px;

            .ant-modal-content {
                height: 100% !important;
                max-height: 100% !important;
            }

            .ant-modal-close-x {
                .anticon-close {
                    background: #FF0039;
                    color: white;
                    padding: .25em;
                    border-radius: 4px;
                    transition: all .2s ease-in-out;
                }

                .anticon-close:hover {
                    scale: 1.2;
                }
            }

            .ant-modal-header {
                .ant-modal-title {
                    font-weight: 700;
                    font-size: 1.25em;
                    color: #4E4E4E;
                    font-family: 'Inter';
                    font-style: normal;
                }

                background-color: #FFDADE;
            }

            .ant-modal-body {

                .tag-box {
                    display: flex !important;
                    gap: 1em;
                }

                .action-btn {
                    display: flex;
                    justify-content: flex-end;
                    gap: 1em;

                    .cancel-btn {
                        color: rgba(0, 0, 0, 0.85) !important;
                        background-color: white !important;
                        border: 1px solid rgba(0, 0, 0, 0.85) !important;
                    }

                    .cancel-btn:hover {
                        color: #FF5A80 !important;
                        background-color: white !important;
                        border: 1px solid #FF5A80 !important;
                    }
                }
            }
        }

        .delete-modal {
            .ant-modal-body {
                display: flex;
                flex-direction: column;
                gap: 1em;


            }

        }
    }


}

.ant-slider-handle {
    border: solid 2px #ffc8d4 !important;
}

.ant-slider-track {
    background-color: #ffc8d4 !important;
}

.ant-input {
    border-radius: 4px !important;
}

.ant-input:hover {
    border-color: #FF5A80 !important;
}

.ant-input:focus {
    border-color: #FF5A80 !important;
    outline: none !important;
    box-shadow: 0 0 0 2px rgba(255, 90, 128, 0.2) !important;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
    border-color: #FF5A80 !important;
    outline: none !important;
    box-shadow: 0 0 0 2px rgba(255, 90, 128, 0.2) !important;
}

.ant-picker {
    border-radius: 4px !important;
    width: 20em;
}

.ant-picker:hover {
    border-color: #FF5A80 !important;
}

.ant-picker-focused {
    border-color: #FF5A80 !important;
    outline: none !important;
    box-shadow: 0 0 0 2px rgba(255, 90, 128, 0.2) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #FF5A80 !important;
    color: #FFFFFF !important;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
    .ant-picker-cell-inner {
        background-color: #FF5A80 !important;
    }
}

.ant-picker-today-btn {
    color: #FF5A80 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.ant-btn-primary {
    background-color: #FF5A80 !important;
    border-color: #FF5A80 !important;
    color: #FFFFFF !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
}


.endTagId {
    .ant-form-item-label>label {
        width: 3em;
    }
}

.ant-select {

    .ant-select-selector {
        color: #696969;

        .ant-select-selection-search {
            .ant-select-selection-search-input {
                // margin-left: 10px;
            }
        }
    }

    .ant-select-selector:hover {
        outline: none !important;
        border: 1px solid #FF5A80 !important;
    }

    .ant-select-selector:focus {
        outline: none !important;
        border: 1px solid #FF5A80 !important;
        z-index: 3;
        box-shadow: 0 0 0 1px #FF5A80 !important;
        caret-color: #FF5A80 !important;

    }

    .ant-select-arrow {
        color: #4E4E4E !important;
        z-index: 3;
    }

    .ant-select-clear {
        color: #4E4E4E !important;
        z-index: 3;
        right: 35px;
        top: 45%;
        width: 7px;
        height: 7px;


    }

    .ant-select-clear:hover {
        opacity: .5;
    }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #FF5A80 !important;
    box-shadow: 0 0 0 2px rgba(255, 24, 193, 0.2) !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    outline: none !important;
    border: 1px solid #FF5A80 !important;
    box-shadow: 0 0 0 1px #FF5A80 !important;
    caret-color: #FF5A80 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #FF5A80 !important;
    color: #FFFFFF;
}

.ant-select-item {
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    color: #696969;
}

.ant-select-item:hover {
    background-color: #FF5A80 !important;
    color: #FFFFFF;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    border-radius: 15px;
    padding: 0;
    background: #EEF2F6;

}

.ant-select-item-option-disabled,
.ant-select-item-option-disabled:hover {
    background: #DFDFDF;
    color: #C0C0C0;
}


.ant-table-cell.ant-table-column-has-sorters,
.ant-table-cell.ant-table-column-has-sorters:hover {
    background-color: #ffc8d4 !important;
}


.ant-table-cell.ant-table-column-sort {
    background: fixed !important;
}

.td.ant-table-column-sort {
    // background: none !important;
}

.ant-table-cell.ant-table-column-sort:hover,
.td.ant-table-column-sort:hover {
    background: fafafa !important;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
    color: #FF5A80 !important;
}

.ant-table-row.ant-table-row-level-0:hover {
    background-color: #fafafa !important;
    // color: #FFFFFF;
}

.ant-tabs {
    .ant-tabs-nav {
        .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {}
        }
    }
}

.ant-tabs-tabpane.ant-tabs-tabpane-active::-webkit-scrollbar {
    display: none !important;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active {
    height: 500px !important;
}

.select_menu {
    height: 100px !important;
}

.select-tag-pen {
    .css-xb97g8 {
        display: none;
    }

    .select__indicator.select__clear-indicator.css-tlfecz-indicatorContainer {
        display: none;
    }

    .select__indicator.select__clear-indicator.css-tlfecz-indicatorContainer {
        display: none;
    }

    .css-1gtu0rj-indicatorContainer {
        display: none;
    }
}

.lst-behaviour-pen {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

@keyframes blink {

    0%,
    100% {
        transform: scale(1, 1);
        // color: black;
        color: red;
    }

    50% {
        transform: scale(1.25, 1.25);
        color: red;
    }
}

.warning-text {
    color: red;
    font-weight: bold !important;
    animation: blink 1s infinite ease-in-out;
}

.classes-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}

.classes-modalContentCreate {
    background-color: white;
    width: 70%;
}

#tabItemLast {
    margin-left: 10px;
}

.ant-picker-dropdown {
    z-index: 9999 !important;
}