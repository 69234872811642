@media (max-width: 900px) {
	.overview-body {
		padding: 20px 5% 60px 5%;
		background-color: #F6F6F6;

		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 15px;
		grid-row-gap: 15px;
		grid-template-rows: 200px 200px 200px 200px 200px;

		.overview-body-item-1 {
			background: linear-gradient(138.87deg, #FF5A80 0%, #FFC19E 99.02%);
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
		}

		.overview-body-item-2 {
			background: linear-gradient(135deg, #6768FF 0%, #52BBFF 100%);
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
		}

		.overview-body-item-3 {
			background: linear-gradient(125.34deg, #46D6A1 0%, #9FDE69 100%);
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
		}

		.overview-body-item-4 {
			background: linear-gradient(135deg, #6C7DEF 0%, #C981F7 100%);
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
		}

		.overview-body-item-5 {
			background: linear-gradient(135deg, #061bda 0%, #f7818b 100%);
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
		}

		.overview-body-item-6 {
			background: linear-gradient(135deg, #d3ef6c 0%, #07000b 100%);
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
		}
	}

	.overview-body-text {
		display: flex;
		flex-direction: column;
		margin: 5% 0px 5% 5%;

		.overview-body-text-small {
			font-weight: 700;
			font-size: 17px;
			line-height: 20px;
			color: #FFFFFF;
			white-space: nowrap
		}

		.overview-body-text-large {
			font-weight: 700;
			font-size: 25px;
			line-height: 50px;
			color: #FFFFFF;
		}
	}
}

@media (min-width: 900px) {
	.overview-body {

		padding: 20px 5% 60px 5%;
		background-color: #f6f6f6c4;

		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 15px;
		grid-row-gap: 15px;
		// grid-template-rows: 200px 200px 200px 200px 200px;
		grid-column-gap: 40px;
		grid-row-gap: 40px;

		.overview-body-item-1 {
			background: linear-gradient(138.87deg, #FF5A80 0%, #FFC19E 99.02%);
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
			cursor: pointer;
		}

		.overview-body-item-2 {
			background: linear-gradient(135deg, #6768FF 0%, #52BBFF 100%);
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
			cursor: pointer;
		}

		.overview-body-item-3 {
			background: linear-gradient(125.34deg, #46D6A1 0%, #9FDE69 100%);
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
			cursor: pointer;
		}

		.overview-body-item-4 {
			background: linear-gradient(135deg, #6C7DEF 0%, #C981F7 100%);
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
			cursor: pointer;
		}

		.overview-body-item-5 {
			background: linear-gradient(135deg, #061bda 0%, #f7818b 100%);
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
			cursor: pointer;
		}

		.overview-body-item-6 {
			background: linear-gradient(135deg, #d3ef6c 0%, #07000b 100%);
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
			cursor: pointer;
		}
	}

	.overview-body-text {
		display: flex;
		flex-direction: column;
		margin: 5% 0px 5% 5%;

		.overview-body-text-small {
			font-weight: 700;
			font-size: 20px;
			line-height: 32px;
			color: #FFFFFF;
		}

		.overview-body-text-large {
			font-weight: 700;
			font-size: 80px;
			line-height: 144px;
			color: #FFFFFF;
		}
	}
}

.ant-pagination-options {
	display: none;
}