.show-modal {
    width: 100vw !important;
    height: 100vh !important;
    z-index: 1000 !important;
    opacity: 1 !important;
    visibility: visible !important;
    transform: scaleY(1) !important;
}

#wrapper-log {
    position: fixed;
    width: 0;
    height: 0;
    z-index: -1000;
    opacity: 0;
    visibility: hidden;
    transform: scaleY(0);
    transition: all 0.3s ease-out 0s;
    transform-origin: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .outside-log-box {
        position: absolute;
        background-color: rgba($color: #000000, $alpha: 0.3);
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
    }

    .log-box {
        //margin: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid white;
        left: 50%;
        width: 65%;
        z-index: inherit;
        // max-height: 90vh;
        margin-top: -10px;

        .log-box__header {
            width: 100%;
            padding: 22px 17px;
            box-sizing: border-box;
            background: #ffdade;
            font-weight: 600;
            display: flex;
            justify-content: space-between;

            .log-close__btn {
                cursor: pointer;
            }
        }

        .log-box__content {
            background: #fff;
            width: 100%;
            // height: 100%;
            padding: 32px;
            box-sizing: border-box;
            position: relative !important;

            .log-form {
                display: block;
                margin: 0 auto;
                width: 90%;

                .log-field {
                    display: grid;
                    grid-gap: 18px;
                    grid-template-columns: 20% 80%;
                    margin-bottom: 12px;

                    .log-label {
                        margin-top: 12px;
                        font-weight: 600;
                        font-size: 14px;
                        text-align: start;
                    }

                    .log-input {
                        border: 1px solid #ffdade;
                        box-sizing: border-box;
                        border-radius: 6px;
                        padding: 12px 32px;
                        width: 100%;
                    }

                    .log-btn {
                        background: #00b1ff;
                        color: #fff;
                        border: none;
                        width: 150px;
                    }
                }

                .log-field.button-submit {
                    display: flex;
                    justify-content: end;

                    button {
                        margin-left: 12px;
                    }
                }
            }
        }

        .log-box__content#detail-food-consume {
            overflow: auto;
            height: 500px;
        }
    }
}